import React, { useRef, useState } from 'react';
import QuotationForm from './components/QuotationForm/QuotationForm';
import generatePDF, { Resolution, Margin, Options } from 'react-to-pdf';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const options: Options = {
  filename: `lanha-baogia-${new Date().toLocaleDateString()}.pdf`,
  method: 'save',
  resolution: Resolution.HIGH,
  page: {
    margin: Margin.NONE,
    format: 'a4',
    orientation: 'landscape',
    pageBreakBefore: true,
  },
};


function App() {
  const pdfRef = useRef(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSave, setIsSave] = useState(false);
  var printOptions = {
    pages: 'all',
    layout: 'landscape',
    background: true,
    color: true,
    paperSize: 'A4',
    pagesPerSheet: 1,
    marginsType: 1,
    scale: 1,
  };

  function resetIsSave() {
    setIsSave(false);
  }

  function printPage() {
    if (isFormValid) {
      window.print(printOptions);
      setIsSave(true);
    } else {
      alert("Vui lòng điền đầy đủ thông tin và chọn nhà cung cấp trước khi xuất PDF.");
    }
  }

  const openPDF = (element) => {
    if (isFormValid) {
      generatePDF(() => pdfRef.current, options);
    } else {
      alert("Vui lòng điền đầy đủ thông tin trước khi xuất PDF.");
    }
  };

  return (
    <Router>
    <ToastContainer />
        <div className="App">
          <div ref={pdfRef} className="page">
            <style>
              {`
                @page {
                  size: A4 landscape;
                }
              `}
            </style>
            <Routes>
              <Route path="/" element={<QuotationForm onFormValidationChange={setIsFormValid} resetIsSave={resetIsSave} isSave={isSave} />} />
              <Route path="/:id" element={<QuotationForm onFormValidationChange={setIsFormValid} />} />
            </Routes>
          </div>
          <button
            onClick={printPage}
            className="no-print"
            style={{
              position: 'fixed',
              bottom: '10px',
              right: '10px',
              zIndex: 1,
              background: '#ff9c04',
              color: 'white',
              borderRadius: '50%',
              padding: '10px 20px',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Xuất PDF
          </button>
        </div>
    </Router>
  );
}

export default App;
