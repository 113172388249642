import React, { useState, useEffect } from "react";
import logo from "../../assets/image/logo.jpg";
import "./QuotationForm.scss";
import TableHeader from "../TableHeader/TableHeader";
import TableBody from "../TableBody/TableBody";
import { Select } from "antd";
import { fetchTrademarks } from "../../utils/apiService";
import { useParams } from "react-router-dom";
import PromotionTable from "../Promotions/Promotions";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Joyride from "react-joyride";

const QuotationForm = ({ onFormValidationChange, resetIsSave, isSave }) => {
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [supplierId, setSupplierId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [date, setDate] = useState(getCurrentDate());
  const [supplierSelected, setSupplierSelected] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [tables, setTables] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [isSupplierSelected, setIsSupplierSelected] = useState(false);
  const [isCustomerInfoValid, setIsCustomerInfoValid] = useState(false);
  const { id } = useParams();

  function handleFormValidation() {
    const isCustomerInfoValid =
      customerName.trim() !== "" &&
      customerPhone.trim() !== "" &&
      customerAddress.trim() !== "";
    setIsCustomerInfoValid(isCustomerInfoValid);
  }
  const [picIdExists, setPicIdExists] = useState(false);
  useEffect(() => {
    const isValid =
      isCustomerInfoValid &&
      isSupplierSelected &&
      phoneValidated &&
      !phoneError;
    onFormValidationChange(isValid);
    console.log(isValid);
  }, [isSupplierSelected, isCustomerInfoValid]);

  useEffect(() => {
    if (isSave) {
      checkPicIdExists();
      resetIsSave();
    }
  }, [isSave]);

  const checkPicIdExists = async () => {
    const postData = {
      name: customerName,
      picId: customerPhone,
      phone: customerPhone,
      projectAddress: customerAddress,
      trademark: supplierId,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    };

    fetch(
      "https://api.lanha.vn/api/v1/quote/product-collection",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setPicIdExists(data.data._id);
        } else {
          //resetIsSave();
          alert("Đã tồn tại pic id.");
        }
      })
      .catch((error) => {
        console.error("Lỗi khi gọi API:", error);
      });
  };
  const [phoneError, setPhoneError] = useState("");
  const [phoneValidated, setPhoneValidated] = useState(false);

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^(84|0[3|5|7|8|9])+([0-9]{8})$/;
    return phoneRegex.test(phoneNumber);
  };
  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    setCustomerPhone(newPhone);
    setPhoneValidated(false);

    // handleFormValidation();
  };
  const handlePhoneBlur = () => {
    if (customerPhone.length > 0) {
      const isValid = validatePhoneNumber(customerPhone);
      setPhoneValidated(true);

      if (!isValid) {
        toast.error(
          "Số điện thoại không hợp lệ. Vui lòng nhập đúng định dạng."
        );
      } else {
        setPhoneError("");
      }
    }
    handleFormValidation();
  };

  const [isTourOpen, setIsTourOpen] = useState(false);
  const steps = [
    {
      target: ".select-trademark",
      content: "Bước 1: Chọn nhà cung cấp.",
      disableBeacon: true,
    },
    {
      target: ".table-cell.product",
      content: "Bước 2: Chọn sản phẩm.",
    },
    {
      target: ".table-cell.description",
      content: "Bước 3: Chọn vật liệu cho sản phẩm.",
    },
    {
      target: ".table-cell.size-item",
      content: "Bước 4: Điền kích thước vào các ô chưa có giá trị sẵn.",
    },
    // {
    //   target: '.table-cell size-item weight',
    //   content: 'Bước 5: Nếu sản phẩm có đơn vị là "Cái" hãy nhập số lượng vào ô này.',
    // },
    {
      target: ".add-row-button",
      content: "Bước 5: Click vào đây để thêm hàng mới.",
    },
    {
      target: ".no-print",
      content: "Bước 6: Click vào đây để xuất file pdf lưu lại bảng báo giá.",
    },
  ];

  const startTour = () => {
    setIsTourOpen(true);
  };

  const closeTour = () => {
    setIsTourOpen(false);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchTrademarks();
        if (data) {
          setSuppliers(data);
        }
      } catch (error) {
        console.error("Lỗi khi truy cập API:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (!id) {
      startTour();
    } else {
      axios
        .get(`https://api.lanha.vn/api/v1/quote/products-custom/${id}`)
        .then((response) => {
          const trademark = response.data.data[0].trademark;
          setSupplierId(trademark);
          const matchingSupplier = suppliers.find(
            (supplier) => supplier.id === trademark
          );
          if (matchingSupplier) {
            setSelectedSupplier(matchingSupplier.name);
          }
        })
        .catch((error) => {
          console.error("Lỗi khi gọi API:", error);
        });
    }
  }, [id]);

  function getCurrentDate() {
    const today = new Date();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    const yyyy = today.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
  }

  function handleDateChange(e) {
    setDate(e.target.value);
  }

  function addTable() {
    const newTable = <TableHeader key={tables.length} />;
    setTables((prevTables) => [...prevTables, newTable]);
  }

  const handleSelectChange = (value) => {
    const supplier = suppliers.find((s) => s.name === value);
    const supplierId = supplier ? supplier._id : "";
    setSelectedSupplier(value);
    setSupplierId(supplierId);
    setIsSupplierSelected(true);
    setCheckError(false);
    setSupplierSelected(true);
    handleFormValidation();
  };

  const handleSelectClick = () => {
    setCheckError(!checkError);
  };

  const handleFormPropsChange = (check) => {
    console.log(check);
    if(check === true){
      toast.error("Vui lòng chọn nhà cung cấp !", { className: 'toast-container' });
      setCheckError(true);
    }
  };

  return (
    <div className="quotation-form" id="dangpro">
      <div className="logo">
        <img src={logo} alt="Your Logo" />
      </div>
      <div className="text-container">
        <div className="left-text">
          <div>
            <strong>Email:</strong>
            <span> lanhainfo@gmail.com</span>
          </div>
          <div>
            <strong>Số điện thoại:</strong>
            <span> 0703 937 521</span>
          </div>
          <div>
            <strong>Showroom:</strong>
            <span> KDC Vạn Phúc, QL13, Hiệp Bình Phước, TP.Thủ Đức</span>
          </div>
          <div>
            <strong>Xưởng sản xuất:</strong>
            <span> 10VP, đường C4 (Phạm Hùng), Quận 8</span>
          </div>
        </div>
        <div className="right-text">
          <div>
            <strong>Khách hàng:</strong>
            <input
              type="text"
              placeholder="Nhập họ và tên"
              value={customerName}
              onChange={(e) => {
                setCustomerName(e.target.value);
                handleFormValidation();
              }}
            />
          </div>
          <div>
            <strong>Số điện thoại:</strong>
            <input
              type="text"
              placeholder="Nhập số điện thoại"
              value={customerPhone}
              onChange={handlePhoneChange}
              onBlur={handlePhoneBlur}
            />
          </div>
          <div>
            <strong>Địa chỉ công trình:</strong>
            <input
              type="text"
              placeholder="Nhập địa chỉ"
              value={customerAddress}
              onChange={(e) => {
                setCustomerAddress(e.target.value);
                handleFormValidation();
              }}
            />
          </div>
          <div>
            <strong>Ngày soạn báo giá:</strong>
            <input type="text" value={date} onChange={handleDateChange} />
          </div>
        </div>
      </div>
      <div className="center-content">
        <Select
          open={checkError}
          className="select-trademark"
          placeholder={
            <span style={{ color: "black",fontWeight : 600 }}>Chọn nhà cung cấp</span>
          }
          onChange={handleSelectChange}
          onClick={handleSelectClick}
        >
          {suppliers.map((supplier, index) => (
            <Select.Option key={index} value={supplier.name}>
              {supplier.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <TableHeader />
      <TableBody supplierId={supplierId} id={id} picIdExists={picIdExists} onFormPropsChange={handleFormPropsChange} />
      <div className="note-2">
        <p className="note-text">
          Báo giá trên chỉ mang tính chất tham khảo do chưa có số liệu công
          trình thực tế. Vui lòng liên hệ sale account qua Fanpage Nội thất Là
          Nhà:
          <a
            href="https://www.facebook.com/noithat.lanha"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            https://www.facebook.com/noithat.lanha
          </a>{" "}
          hoặc hotline/zalo:
          <a
            href="https://zalo.me/0703937521"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            https://zalo.me/0703937521
          </a>{" "}
          để được tư vấn đo đạc chính xác nhất.
        </p>
      </div>
      <Joyride
      hideCloseButton={true}
      steps={steps}
      run={isTourOpen}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      disableOverlay={false}
      spotlightClicks={true}
      disableCloseOnEsc={true}
      disableOverlayClose={true}
      disableScrollParentFix={true}
      callback={({ index, status }) => {
        if (status === "finished") {
          closeTour();
        }
      }}
      styles={{
        options: {
          arrowColor: "#03a9f4",
          backgroundColor: "#ffffff",
          overlayColor: "rgba(0, 0, 0, 0.4)",
          primaryColor: "#03a9f4",
          textColor: "#333",
        },
        tooltip: {
          borderRadius: "8px",
          padding: "20px",
          textAlign: "center",
        },
      }}
    />
    </div>
  );
};

export default QuotationForm;
